import React from "react"
import styled from 'styled-components'
import { FormattedMessage } from 'gatsby-plugin-intl'

const Foot = styled.section`
    background: #000;
    padding: 2rem 0;
`

const Contain = styled.div`
    color: #fff;
    margin: 0 auto;
    width: 80%;
    display: flex;
`

const LogoImgWrapper = styled.div`
    margin: 0 20px 0 0;
    padding-bottom: 2rem;
    width: 20%;
    min-width: 90px;
    img {
        width: 100%;
    }
`

const Footer = () => {
    return (
        <Foot>
            <Contain>
              <LogoImgWrapper>
                <img src={require('../images/tcr-logo.png')} alt="Chicago Reporter logo" />
              </LogoImgWrapper> 
              <div>
                <p><FormattedMessage id="footer.description"/></p>
                <p><FormattedMessage id="footer.address"/></p>
              </div>
            </Contain>
        </Foot>
    )
}

export default Footer